body{font-family: 'Roboto', sans-serif;background: #F8F9FB;}
.re-login-card{max-width: 440px; border-radius: 2px;border: 1px solid #E8EBF0;background: #FFF;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  .card-body{padding: 20px}
  .card-img-top{height: 200px;     border-top-left-radius: 2px;
    border-top-right-radius: 2px;}
  .re-login-logo{position: absolute;top: 50%;z-index: 99;left: 50%;transform: translate(-50%, -50%);max-width: 100%; width: auto;height: 90px; padding: 30px}
  .card-title{color: #000006;font-size: 14px; font-weight: 400; line-height: 20px; letter-spacing: 0.28px;margin-bottom: 20px;}
  .form-label{color: #000006;font-size: 10px;font-style: normal;font-weight: 500;line-height: 15px;letter-spacing: 0.2px; margin-bottom: 5px;}
}
.re-content-area{display: flex; height: 100vh; flex-direction: column;
  .re-main-content{flex: 1 1 0%; display: flex; align-items: center;
    .container{height: 100%;display: flex; flex-direction: column; align-items: center; justify-content: center;
      .row{height: 100%}
    }
  }

}
.re-footer-content{flex: 0 0 auto; display: flex;justify-content: center;
  span{padding: 20px;text-align: center;color: #4F6780;font-size: 12px; font-style: normal;font-weight: 400;line-height: 20px;letter-spacing: 0.24px; cursor: pointer;}
}
.re-order-content{
  .re-content-area{height: calc(100vh - 61px);}
}
.form-control{background: #F8F9FB;padding: 14px 15px;color: #000006;font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;letter-spacing: 0.28px;border-radius: 4px;border: 1px solid #E8EBF0;
&::placeholder {
  opacity: 50%;
}
}

.form-control:focus{background: #F8F9FB;color: #000006;box-shadow: none;}
.btn{font-size: 14px;color:#000; line-height: 22px;padding: 8px 16px;border-radius: 4px;border-width: 1px; border-style: solid;letter-spacing: 0.28px;}
.btn-sm{padding: 6px 16px;}
.btn-lg{padding: 13px 26px;}
.btn-light{border-color: #E8EBF0;background: #FFF;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);color: #000006;
  &:hover{background: #FFF;border-color: #E8EBF0; color: #000006;}
  &:active{background: #FFF!important;border-color: #E8EBF0!important; color: #d84849!important;}
}
.btn-primary{color: #fff;font-weight: 500;transition: all .3s ease-in-out;}

.btn-primary:hover, .btn-primary:focus-visible{color: #fff;}
.btn-primary.disabled, .btn-primary:disabled{color: #000006;opacity: .3;font-weight: 400;}

.badge{padding: 2px 5px;font-size: 10px; font-style: normal; font-weight: 400; line-height: 15px; letter-spacing: 0.2px; border-radius: 4px;
  &.bg-secondary{background-color: #dfe0e2 !important; color: #707073!important;}
  &.bg-bg-danger, &.bg-danger{background-color: #fbeded !important; color: #d84849!important;
    &.active{background-color: #fdf6f6 !important; color: #eba3a4!important;}
  }
  &.bg-success, &.bg-bg-success{background-color: #5db24e !important; color: #FFFFFF!important;
    &.active{background-color: #f0f8f0 !important; color: #6dba61!important;}
  }
  &.bg-light{background-color: transparent !important; color: #808082!important; border-radius: 4px; border: 1px solid #e8ebf0;}
}
.is-disabled{opacity: .5; cursor: none; pointer-events: none}

.alert{border-radius: 4px;padding: 7px 10px 8px 10px;border-width: 0;font-size: 10px;font-style: normal;font-weight: 400;line-height: 15px;letter-spacing: 0.2px;}
.alert-danger{color: #D84848;background: #EB3D3D19;}
.alert-primary{color: #767982;background: #EBF2FE;}

.invalid-feedback{color: #D84848;font-size: 10px;font-style: normal;font-weight: 400;line-height: 15px;letter-spacing: 0.2px;}

.re-order-header{padding: 10px 20px; border-bottom: 1px solid #E8EBF0;background: #FFF;    position: sticky;
  top: 0;
  z-index: 99;}
.re-header-row {display: flex;align-items: center;
.re-header-col-back{flex: 0 0 calc(50% - 100px); max-width: calc(50% - 100px)}
.re-header-logo{flex: 0 0 200px; max-width: 200px; text-align: center;
  img{width: 55px;}
}
.re-header-col-action{flex: 0 0 calc(50% - 100px); max-width: calc(50% - 100px); display: flex; flex-wrap: wrap;justify-content: flex-end; align-items: center;
  .header-product-count{
    h6{color: #000006;
      text-align: right;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.24px;
      margin: 0;
    }
    p{color: #4F6780;
      text-align: right;
      margin: 0;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.24px;}
  }
}
}
.re-wrapperRef{position: fixed; top: 61px; bottom: 0; left: 0;
  right: 0; display: block}
.re-main{position: relative; z-index: 1;max-height: 100%;width: 100%; text-align: center; padding-top: 15px; padding-bottom: 15px}
.re-card{display: inline-flex;text-align: left;min-height: 490px; flex-direction: column;
  border-radius: 2px; border: 1px solid #E8EBF0; background: #FFF; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15); max-width: 440px; width: 100%;
  .re-card-content {
    flex: 1 1 0%;
    position: relative;
  }
  .re-card-header{
    padding: 20px;
    border-bottom: 1px solid #E8EBF0;
    p{margin: 0}
    .re-order-no{
      display: flex; align-items: center; gap: 6px; margin-bottom: 5px; color: #000006; font-size: 12px; font-style: normal; font-weight: 400; line-height: 20px; letter-spacing: 0.24px;
      span{opacity: 0.5;}
    }
    .re-order-title{
      h2{margin: 0; color: #000006; font-size: 20px;font-style: normal; font-weight: 500; line-height: 120%; letter-spacing: 0.4px;}
    }
  }
}
.re-loading{display: flex; align-items: center; justify-content: center; margin: 100px 204px;}
.loading-product{
  display: flex;justify-content: center; align-items: center;
  padding: 10px 0;
}
ul.re-order-product{
  margin: 0; padding: 0; list-style: none; display: inline-block; width: 100%;position: static; min-height: 100%;
  li{border-bottom: 1px solid #E8EBF0; padding: 15px 20px; display: flex; align-items: center; gap: 20px; position: static; cursor: default;
    &.active{background-color: #F8F9FB}
    &.re-reason-input{display: block;}
    //&:last-child{border-bottom: 0;}
    .re-product-checkbox{cursor: pointer;
      .form-check-input{width: 20px; height: 20px; border-radius: 2px; border: 1px solid #E8EBF0; background-color: #FFF; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15); margin: 0;cursor: pointer;
        &:active {filter: none;}
      }
    }
    .re-product-details{
      display: flex; align-items: center; gap: 15px; flex: 1 1 0%; position: relative;
      .re-product-img{display: inline-block;max-width: 60px;position: relative;line-height: 0;flex: 0 0 60px;cursor: pointer;
        &:before{content: '';width: 100%;padding-bottom: 133.35%;display: inline-block;position: relative;}
        img{border: 0;width: 100%;height: 100%;position: absolute;top: 0;left: 0;object-fit: cover;object-position: center;border-radius: 4px;}
      }

      .re-product-detail-content{ cursor: pointer;
        h2{color: #000006;font-size: 12px;font-style: normal;font-weight: 500;line-height: 20px;letter-spacing: 0.24px; margin: 0}
        p{color: #000006; font-size: 12px; font-style: normal; font-weight: 400;line-height: 20px;letter-spacing: 0.24px; margin: 0}
        .re-product-variant{color: #4F6780; margin-bottom: 10px}
      }
      .re-product-price{display: flex; gap: 10px}
    }
    .re-product-action{display: flex; align-items: center; gap: 10px; cursor: pointer;}
    &.re-product-disabled{background: #F8F9FB;pointer-events: none;
      .re-product-checkbox, .re-product-details{
        opacity: 0.5;
      }
    }
    .re-options-content{width: 100%; display: inline-flex; position: absolute;top:-1px; left: 100%; border: 1px solid #E8EBF0; background-color: #FFFFFF; max-width: 345px;border-radius: 2px;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15); bottom: -1px;flex-direction: column;
      .accordion{flex: 1 1 0%;
        overflow: auto;}
      .re-option-header{border-bottom: 1px solid #E8EBF0; display: flex; align-items: center;
        .re-option-header-back-btn{padding: 15px; border-right:1px solid #E8EBF0; cursor: pointer; }
        h6{margin: 0; padding: 15px 20px; color: #000006; font-size: 12px;font-style: normal;font-weight: 500;line-height: 20px;letter-spacing: 0.24px;}
      }
      ul{margin: 0; padding: 0; list-style: none; display: inline-block; width: 100%;
        &.re-product-list{overflow: auto}
        li{color: #000006;font-size: 12px;font-style: normal;font-weight: 400;line-height: 20px;letter-spacing: 0.24px;justify-content: space-between; padding-right: 10px; cursor: pointer;
          .re-option-variant{display: flex; align-items: center; gap: 10px;
           .re-option-variant-title{color: #000006; font-size: 12px; font-style: normal; font-weight: 400; line-height: 20px;letter-spacing: 0.24px;}
           .re-option-variant-price{color: #4F6780; font-size: 12px; font-style: normal; font-weight: 400; line-height: 20px;letter-spacing: 0.24px;}
          }
        }
      }
    }
    &.FilterTypeTwoImg{
      .re-product-img{display: inline-block;max-width: 35px;position: relative;line-height: 0;flex: 0 0 35px;
        &:before{content: '';width: 100%;padding-bottom: 142.9%;display: inline-block;position: relative;}
        img{border: 0;width: 100%;height: 100%;position: absolute;top: 0;left: 0;object-fit: cover;object-position: center;border-radius: 4px;}
      }
    }
  }
}

.accordion{
  .accordion-item{border: 0; border-bottom: 1px solid #E8EBF0;  border-radius: 0;
    &:last-child{border-bottom: 0;}
    .accordion-button{padding: 15px 10px 15px 20px;color: #000006; font-size: 12px; font-style: normal; font-weight: 400;line-height: 20px;letter-spacing: 0.24px;
      &:not(.collapsed){background-color: transparent}
    &:focus{box-shadow: none; outline: none}
      &:after{transform: rotate(360deg);}
      &.collapsed:after{transform: rotate(270deg);}
    }
    .accordion-body{padding: 5px; background-color: #f8f9fb;
      .re-product-list{
        display: flex; flex-direction: column; gap: 5px;
        li{border-radius: 2px;border: 1px solid #E8EBF0;background: #FFF; padding: 10px 20px;
          .FilterTypeTwoImg{
            display: flex; flex: 10px; align-items: center;
          }
        }
      }
    }
  }
}
.re-summary{padding-top: 15px; display: flex;flex-direction: column;justify-items: center;}
.re-summary-container{
  display: flex; max-width: 440px; margin: 0 auto;align-items: flex-start; width: 100%; position: relative;
  .re-summary-col{flex: 0 0 100%; max-width: 100%; border-radius: 2px;border: 1px solid #E8EBF0;background: #FFF;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    &:has(.re-summary-header){margin-bottom: -1px; border-bottom: 0; border-top-width: 1px}
    .re-summary-header{
      padding: 20px;
      p{margin: 0}
      .re-order-no{
        display: flex; align-items: center; gap: 6px; margin-bottom: 5px; color: #000006; font-size: 12px; font-style: normal; font-weight: 400; line-height: 20px; letter-spacing: 0.24px;
        span{opacity: 0.5;}
      }
      .re-order-title{
        h2{margin: 0; color: #000006; font-size: 20px;font-style: normal; font-weight: 500; line-height: 120%; letter-spacing: 0.4px;}
      }
    }
  }
  .re-summary-product-col {flex: 0 0 100% ;max-width: 345px;border-radius: 2px; border: 1px solid #E8EBF0; background: #FFF;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15); width: 100%; position: absolute; left: 100%; top: 0;
    bottom: 0;
    border-top: 0;
    .re-summary-product-content {
      .re-summary-product {
        ul {margin: 0;padding: 0;list-style: none;display: inline-block;width: 100%; max-height: 231.5px;overflow: auto;
          li {padding: 10px 20px;display: flex;align-items: center;gap: 15px;border-bottom: 1px solid #E8EBF0;
            &:last-child {border-bottom: 0;}
            .re-product-img {display: inline-block;max-width: 60px;position: relative;line-height: 0;flex: 0 0 60px;
              &:before {content: '';width: 100%;padding-bottom: 133.35%;display: inline-block;position: relative;}
              img {border: 0;width: 100%;height: 100%;position: absolute;top: 0;left: 0;object-fit: cover;object-position: center;border-radius: 4px;}
            }
            .re-product-details {display: flex;align-items: center;gap: 10px;    flex: 1 1 0%;
              .re-product-content{
                flex: 1 1 0%;
                h6 {color: #000006;font-size: 12px;font-style: normal;font-weight: 500;line-height: 20px;letter-spacing: 0.24px; margin: 0}
                .re-product-variant{color: #4F6780; font-size: 12px; font-style: normal; font-weight: 400;line-height: 20px;letter-spacing: 0.24px; margin-bottom: 10px}
                .re-product-price{color: #000006; font-size: 12px; font-style: normal; font-weight: 400;line-height: 20px;letter-spacing: 0.24px; margin-bottom: 0;
                  .re-item-price-discount {
                    color: #4f6780;
                    text-decoration: line-through;
                  }
                }
              }


            }
          }
        }
      }
    }
  }
  .re-summary-title{
    padding: 10px 20px; border-bottom: 1px solid #E8EBF0; border-top: 1px solid #E8EBF0;
    &:last-child{border-top: 0;}
    h6{color: #000006; font-size: 12px; font-style: normal; font-weight: 500; line-height: 20px; letter-spacing: 0.24px;margin: 0}
  }
  .re-summary-body{padding: 20px;
    ul{margin: 0; padding: 0; display: inline-block; list-style: none; width: 100%;
      li{margin-bottom: 10px; display: flex; align-items: center; justify-content: space-between;
        &:last-child, .alert{margin-bottom: 0}
        span{color: #000006; font-size: 12px; font-style: normal; font-weight: 400; line-height: 20px; letter-spacing: 0.24px;}
        .refund-payment{font-weight: 700; color: #6DB961;}
        .additional-payment{font-weight: 700; color: #D84848;}
      }
    }
  }
  .re-summary-address{
    border-bottom: 1px solid #E8EBF0;
    p{color: #000006;font-size: 12px;font-style: normal;font-weight: 400;line-height: 20px;letter-spacing: 0.24px; margin: 0}
  }
  .re-summary-action{display: flex; gap: 10px}
  &:first-child{
    .re-summary-col {
      border-bottom: 0;
    }
  }
  &:last-child{
    .re-summary-col {
      border-top: 0;
    }
  }
}
.re-confirmation{
  .re-card{width: 100%; max-width: 440px; min-height: 100%}
  .re-return-instructions{color: #000006;font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;letter-spacing: 0.28px; margin-top: 20px;
    a{text-decoration-line: underline;color: #000006;}
  }
  .re-card-content{
    padding: 20px;
    .re-return-days{margin-top: 20px; color: rgba(0, 0, 6, 0.50); text-align: center;font-size: 10px;font-style: normal;font-weight: 400;line-height: 15px; max-width: 230px;
      margin-left: auto;
      margin-right: auto;
      p{margin: 0}
    }
  }
}

@media(max-width: 1199px) {
  .re-card{min-height: 100%}
  ul.re-order-product{
    li{flex-wrap: wrap;gap: 10px 20px;
      .re-options-content {
        width: auto;
        position: static;
        left: 0;
        box-shadow: none;
        flex: 0 0 calc(100% + 40px);
        max-width: initial;
        margin: 0 -20px 0px -20px;
        border-left: 0;border-right: 0; border-bottom: 0; line-height: 0;
        ul.re-product-list {background-color: #F8F9FB}
      }
      .accordion{
        .accordion-item{
          .accordion-button {background-color: #F8F9FB;
            &:not(.collapsed){background-color: #F8F9FB;}
          }
        }
      }
      &.active{padding-bottom: 0}
    }

    .active{
      .re-product-action{
       svg{ transform: rotate(90deg);}
      }
    }
  }
  .re-summary{padding-top: 15px; height: 100%}
  .re-summary-container{
    flex-wrap: wrap;
    .re-summary-product-col{position: static; max-width: 100%; order: 0; border-top: 0;border-bottom: 0;
      .re-summary-product-content{
        .re-summary-product {
          ul {max-height: 100%; overflow: hidden}
        }
      }
    }
    }
    .re-summary-col{order: 1}
  }


@media(max-width: 991px) {

}
@media(max-width: 767px) {

  .re-order-header {
    padding: 10px 12px;

    .re-header-col-back {
      flex-basis: calc(50% - 50px);
      max-width: calc(50% - 50px);
    }

    .re-header-logo {
      flex: 0 0 100px;
      max-width: 100px;

    }
    .re-header-col-action {
      flex-basis: calc(50% - 50px);
      max-width: calc(50% - 50px);
    }
  }
}

@media(max-width: 575px) {
  //375

  .re-login-card{
    .card-title{font-size: 12px}
  }
  .re-card {
    .re-card-header {
      .re-order-title {
        h2 {
          font-size: 16px;
          letter-spacing: 0.32px;
        }
      }
    }
  }
  ul.re-order-product{
    li{position: relative;}
    li.re-product-disabled{
      .re-product-checkbox{display: none}
    }
  }
  .re-product-checkbox{
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 99;
    transform: translate(0, -50%);
    .form-check-input{display: none}
    .form-check-input:checked{
      display: block;
    }
  }
}